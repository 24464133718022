import { createTheme } from "@mui/material"

import colors from "./../clux-ui/tokens/_colors.module.scss"
import { sizes } from "./sizes"

const palette = {
  primary: {
    light: colors.purpleLight,
    main: colors.purple,
    dark: colors.purpleDark,
    contrastText: colors.white,
  },
  secondary: {
    light: colors.pinkLight,
    main: colors.pink,
    dark: colors.pinkDark,
    contrastText: colors.white,
  },
}

export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: colors.white,
          fontSize: "1rem",
          backgroundColor: palette.secondary.main,
          borderRadius: sizes.borderRadius,
          padding: "0.5rem 1.2rem",
          "&:hover": {
            backgroundColor: palette.primary.main,
          },
          "&.MuiButton-outlined": {
            backgroundColor: colors.transparent,
            borderColor: palette.secondary.main,
            color: palette.secondary.main,
          },
          "&.MuiButton-text": {
            backgroundColor: colors.transparent,
            borderColor: colors.transparent,
            color: palette.primary.main,
            "&:hover": {
              textDecoration: "underline",
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px", // TODO _ utiliser un radius token
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "16px", // TODO _ utiliser un radius token
        },
      },
    },
  },
  palette,
})
