import React from "react"

import {
  Container,
  Dialog,
  Slide,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { TransitionProps } from "@mui/material/transitions"

import { ColorBar } from "../../clux-ui/components"

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const SettingsDialog = ({ open, children, ...props }) => {
  const theme = useTheme()
  const isResponsive = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <Dialog
      sx={{
        ".MuiDialog-container > .MuiPaper-root": {
          borderRadius: `${isResponsive ? "0 0" : "1rem 1rem"} 1.5rem 1.5rem`,
          transition: "border-radius 0.5s ease",
          outline: "2px solid black",
        },
      }}
      fullScreen
      open={open}
      TransitionComponent={Transition}
      {...props}
    >
      <ColorBar randomColor />
      <Container>{children}</Container>
    </Dialog>
  )
}

export default SettingsDialog
