import React from "react"

import Button from "../Button"

const ButtonPrimary = ({ children, ...props }) => {
  return (
    <Button variant="contained" {...props}>
      {children}
    </Button>
  )
}

export default ButtonPrimary
