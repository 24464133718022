import React from "react"

import { Stack } from "@mui/material"

const StyleGuideContainer = ({ children }) => {
  return (
    <Stack spacing={2} mt={5} mb={5}>
      {children}
    </Stack>
  )
}

export default StyleGuideContainer
