import React from "react"

import { Box, Typography } from "@mui/material"

import { Card } from "../../../../components"

const Cards = () => {
  return (
    <Box display="flex" gap={2} flexWrap={"wrap"}>
      <Card
        actions={[
          {
            label: "Action",
            onClick: () => {},
          },
        ]}
      >
        <Typography variant="h5">Titre</Typography>
        <Typography>Description</Typography>
      </Card>
    </Box>
  )
}

export default Cards
