import React from "react"

import Button from "../Button"

const ButtonTertiary = ({ children, ...props }) => {
  return (
    <Button variant="text" {...props}>
      {children}
    </Button>
  )
}

export default ButtonTertiary
