import React from "react"

import { Box } from "@mui/material"

import { ColorBar } from "../../../../components"

const Couleurs = () => {
  return (
    <Box display="flex" gap={2} flexWrap={"wrap"}>
      <p>TODO _ Ici une palette de couleur</p>
      <ColorBar />
      <ColorBar withGradient />
      <ColorBar randomColor />
    </Box>
  )
}

export default Couleurs
