import React from "react"

import { kebabCase } from "lodash"

import {
  CardActions,
  CardContent,
  Card as MuiCard,
  styled,
} from "@mui/material"

import ButtonPrimary from "../ButtonPrimary"

const StyledCard = styled(MuiCard)(({ theme }) => ({
  padding: "0.5rem",
  borderRadius: "0.5rem 0.5rem 2rem 2rem",
  borderTop: `solid 0.5rem ${theme.palette.secondary.main}`,
}))

const Card = ({ actions, children, ...props }) => {
  return (
    <StyledCard {...props}>
      <CardContent>{children}</CardContent>
      <CardActions>
        {actions.map(action => (
          <ButtonPrimary
            key={`atelier-${kebabCase(action.label)}`} // Exporter getKey dans les helpers clux-ui
            onClick={action.onClick}
          >
            {action.label}
          </ButtonPrimary>
        ))}
      </CardActions>
    </StyledCard>
  )
}

export default Card
