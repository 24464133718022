import React, { useState } from "react"

import { Box } from "@mui/material"

import { ButtonPrimary } from "../../../../clux-ui/components"
import { SettingsDialog } from "../../../../components"

const Modales = () => {
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false)

  return (
    <>
      <Box display="flex" gap={2} flexWrap={"wrap"}>
        <ButtonPrimary onClick={() => setSettingsDialogOpen(true)}>
          Ouvrir une modale plein ecran
        </ButtonPrimary>
      </Box>
      <SettingsDialog open={settingsDialogOpen}>
        <Box p={2}>
          <ButtonPrimary onClick={() => setSettingsDialogOpen(false)}>
            Fermer
          </ButtonPrimary>
        </Box>
      </SettingsDialog>
    </>
  )
}

export default Modales
