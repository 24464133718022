import React from "react"

import { kebabCase } from "lodash"
import { useNavigate } from "react-router-dom"

import { Container, Grid2, Typography } from "@mui/material"

import { Card } from "../../clux-ui/components"
import { ROUTES_ATELIERS } from "../../constants/routes"

const ChoixAtelier = () => {
  const navigate = useNavigate()

  return (
    <Container sx={{ marginTop: 5 }}>
      <Grid2 container spacing={2}>
        {Object.values(ROUTES_ATELIERS).map(atelier => (
          <Grid2
            key={`atelier-${kebabCase(atelier.label)}`} // Exporter getKey dans les helpers clux-ui
            size={{ xs: 12, sm: 6, md: 4, lg: 3 }}
          >
            <Card
              actions={[
                {
                  label: "Demarrer",
                  onClick: () => {
                    navigate(atelier.path)
                  },
                },
              ]}
            >
              <Typography variant="h5">{atelier.label}</Typography>
              <Typography>{atelier.description}</Typography>
            </Card>
          </Grid2>
        ))}
      </Grid2>
    </Container>
  )
}

export default ChoixAtelier
