import React from "react"

import { Formik } from "formik"
import { get, join, keys, set } from "lodash"
import { Link } from "react-router-dom"

import {
  Box,
  Slider,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material"

import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonTertiary,
} from "../../../../clux-ui/components"
import { ACCENTS, PONCTUATIONS } from "../../../../clux-ui/constants/strings"
import { SettingsDialog } from "../../../../components"
import { ROUTES } from "../../../../constants/routes"
import { STEPS } from "../../../../constants/values"
import { getRandomNumbers } from "../../../../helpers/nombres"
import {
  getSliderLabel,
  LECTURE_FLASH_DURATIONS,
  LECTURE_FLASH_PARAMS_DEFAULT_VALUES,
  LectureFlashParams,
} from "../params"

import "./index.scss"

const sliderMarks = keys(LECTURE_FLASH_DURATIONS).map(key => ({
  value: Number(key),
  label: LECTURE_FLASH_DURATIONS[key].label,
}))

const Settings = ({
  open,
  params,
  setParams,
}: {
  open: boolean
  params: LectureFlashParams
  setParams: Function
}) => {
  const theme = useTheme()
  const isResponsive = useMediaQuery(theme.breakpoints.down("md")) // TODO _ créer un hook useIsResponsive

  const handleStart = values => {
    setParams({
      ...params,
      ...getNewParamsValues(values),
      step: STEPS.ATELIER,
      index: LECTURE_FLASH_PARAMS_DEFAULT_VALUES.index,
    })
  }

  // TODO _ maj params a chaque onChange & onBlur ?
  // const handleUpdateParams = (values) => {
  //   setParams({
  //     ...params,
  //     ...getNewParamsValues(values),
  //   });
  // };

  // TODO _ gérer le réglage affichage infini

  // TODO _ si besoin : generateRandomList(words, nbWords)
  const getNewParamsValues = values => ({
    words: values.words.split(";"),
    wordsToDisplay: values.words.split(";"),
    displayDuration: values.displayDuration,
  })

  // TODO _ créer un compo segment avec header violet pour le titre, exemple : Reglages
  return (
    <SettingsDialog open={open}>
      <Box className="lecture-flash-settings" p={2}>
        {params.step === STEPS.PRESENTATION && (
          <>
            <h2>Un atelier pour lire des mots !</h2>
            <ButtonPrimary
              onClick={() => setParams({ ...params, step: STEPS.PARAMETRES })}
            >
              Demarrer
            </ButtonPrimary>
          </>
        )}
        {params.step === STEPS.PARAMETRES && (
          <>
            <h2>Reglages</h2>
            {/* TODO _ selectionner ici listes */}
            <Formik
              initialValues={{
                words: join(params.words, ";"),
                displayDuration: params.displayDuration,
                generatedNumbersQuantity: 5,
                generatedNumbersLength: 4,
              }}
              validate={values => {
                // console.log('values', values);

                const errors = {}

                const REGEX_MOT_OU_NOMBRE = `([A-Za-z${ACCENTS}${PONCTUATIONS}]+|[0-9]+)`
                const REGEX_MOTS = new RegExp(
                  `^(${REGEX_MOT_OU_NOMBRE}(;${REGEX_MOT_OU_NOMBRE})*)$`,
                )
                if (!get(values, "words")) {
                  set(errors, "words", "Il faut ajouter des mots !") // TODO _ mettre tous les texts dans un fichier
                } else if (!REGEX_MOTS.test(get(values, "words"))) {
                  set(
                    errors,
                    "words",
                    "Il faut séparer les mots par un point-virgule",
                  )
                }
                return errors
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  // console.log(JSON.stringify(values, null, 2));
                  setSubmitting(false)
                  handleStart(values)
                }, 400)
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    name="words"
                    label="Mots"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={get(values, "words")}
                    color="primary" // TODO _ surcharger la couleur primary
                    sx={{
                      m: "1rem 0",
                    }}
                    error={get(touched, "words") && !!get(errors, "words")}
                    helperText={get(errors, "words")}
                  />
                  <Stack direction="row" spacing={2} useFlexGap>
                    {/* TODO _ minValue à 1 */}
                    <TextField
                      type="number"
                      name="generatedNumbersQuantity"
                      label="Nombre de nombres"
                      size="small"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={get(values, "generatedNumbersQuantity")} // TODO _ nom des champs du Form dans une enum en haut du fichier
                      color="primary"
                    />
                    {/* TODO _ minValue à 1 */}
                    <TextField
                      type="number"
                      name="generatedNumbersLength"
                      label="Longueur des nombres"
                      size="small"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={get(values, "generatedNumbersLength")}
                      color="primary"
                    />
                    <ButtonTertiary
                      onClick={() => {
                        const nombres = getRandomNumbers(
                          get(values, "generatedNumbersQuantity"),
                          get(values, "generatedNumbersLength"),
                        )
                        setFieldValue("words", join(nombres, ";"))
                      }}
                      disabled={isSubmitting}
                    >
                      Generer des nombres
                    </ButtonTertiary>
                  </Stack>

                  <p>
                    {`Temps d'affichage du mot : ${getSliderLabel(
                      get(values, "displayDuration"),
                    )}`}
                  </p>
                  <Stack
                    className="time-stack"
                    direction="row"
                    spacing={2}
                    useFlexGap
                    sx={{
                      m: "2.5rem 0 0 0",
                    }}
                  >
                    <p>10ms</p>
                    <Slider
                      aria-label="Durée d'affichage"
                      name="displayDuration"
                      value={get(values, "displayDuration")}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      valueLabelDisplay={isResponsive ? "on" : "auto"}
                      getAriaValueText={getSliderLabel}
                      valueLabelFormat={getSliderLabel}
                      step={null}
                      min={0}
                      max={100}
                      marks={sliderMarks}
                      sx={{
                        "& .MuiSlider-markLabel": {
                          display: "none",
                        },
                      }}
                    />
                    <p>∞</p>
                  </Stack>
                  <br />
                  <br />
                  <ButtonPrimary onClick={handleSubmit} disabled={isSubmitting}>
                    Commencer
                  </ButtonPrimary>
                </form>
              )}
            </Formik>
          </>
        )}
        {params.step === STEPS.RESULTATS && (
          <>
            <h2>Resultats</h2>
            <ButtonPrimary
              onClick={() => setParams({ ...params, step: STEPS.PARAMETRES })}
              mr={2}
            >
              Recommencer
            </ButtonPrimary>
            <Link to={ROUTES.ATELIERS.path}>
              <ButtonSecondary sx={{ ml: "0.5rem" }}>Quitter</ButtonSecondary>
            </Link>
          </>
        )}
      </Box>
    </SettingsDialog>
  )
}

export default Settings
