import React from "react"

import { random, size } from "lodash"

import { Box, Paper } from "@mui/material"

import { cluxColors } from "../../style/colors"

const nbColors = cluxColors.length
const gradientWidth = 5 // TODO _ Passer en props avec valeur par defaut à 5
const colorWidth = (100 - (nbColors - 1) * gradientWidth) / nbColors
const gradientValue = cluxColors.reduce((result, color, index) => {
  const posX = index * (colorWidth + gradientWidth)
  return result + `, ${color} ${posX}%, ${color} ${posX + colorWidth}%`
}, "to right")

// TODO _ ajouter une regle ts pour bloquer la compilation si la valeur par defaut d'une props optionelle n'est pas renseignée

// TODO _ Simplifier, garder seulement la 1ere partie gradient -> gradient à 0 par defaut
const ColorBar = ({
  height = "1rem",
  withGradient = false,
  randomColor = false,
}: {
  height?: string
  withGradient?: boolean
  randomColor?: boolean
}) => {
  return (
    <Paper sx={{ width: "100%" }}>
      {withGradient ? (
        <Box
          sx={{
            width: "100%",
            height,
            background: `linear-gradient(${gradientValue})`,
          }}
        />
      ) : (
        <Box display="flex" sx={{ width: "100%" }}>
          {randomColor ? (
            <Box
              // TODO _ exporter la fonction getRandomColor pour l'utiliser ailleurs
              sx={{
                backgroundColor: cluxColors[random(0, size(cluxColors) - 1)],
                transition: "background-color 0.5s ease",
                width: "100%",
                height,
              }}
            />
          ) : (
            cluxColors.map(color => (
              <Box
                key={color}
                sx={{
                  backgroundColor: color,
                  width: `${100 / nbColors}%`,
                  height,
                }}
              />
            ))
          )}
        </Box>
      )}
    </Paper>
  )
}

export default ColorBar
