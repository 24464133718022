import React from "react"

import { Container } from "@mui/material"

import { StyleGuide as CluxUiStyleGuide } from "../../clux-ui/scenes"
import {
  StyleGuideContainer,
  StyleGuideSection,
} from "../../clux-ui/scenes/StyleGuide/components"
import { Modales } from "./sections"

const StyleGuide = () => {
  const sections = [{ titre: "Modales", compo: <Modales /> }]

  return (
    <Container>
      <StyleGuideContainer>
        {/* Les sections du styleguide clux-ui */}
        <CluxUiStyleGuide />
        {/* Les sections du styleguide non partagées */}
        {sections.map(section => (
          <StyleGuideSection titre={section.titre} key={section.titre}>
            {section.compo}
          </StyleGuideSection>
        ))}
      </StyleGuideContainer>
    </Container>
  )
}

export default StyleGuide
