import React from "react"

import { StyleGuideSection } from "./components"
import { Boutons, Cards, Couleurs, Typographie } from "./sections"

const StyleGuide = () => {
  const sections = [
    { titre: "Typographie", compo: <Typographie /> },
    { titre: "Couleurs", compo: <Couleurs /> },
    { titre: "Boutons", compo: <Boutons /> },
    { titre: "Cartes", compo: <Cards /> },
  ]

  return (
    <>
      {sections.map(section => (
        <StyleGuideSection titre={section.titre} key={section.titre}>
          {section.compo}
        </StyleGuideSection>
      ))}
    </>
  )
}

export default StyleGuide
