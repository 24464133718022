import React, { useMemo, useState } from "react"

import { Box } from "@mui/material"

import { ButtonPrimary, ButtonSecondary } from "../../../clux-ui/components"
import { STEPS } from "../../../constants/values"
import { getSliderValue, LECTURE_FLASH_PARAMS_DEFAULT_VALUES } from "./params"
import Settings from "./Settings"

import "./style.scss"

const LectureFlash = () => {
  const [params, setParams] = useState(LECTURE_FLASH_PARAMS_DEFAULT_VALUES)
  const [word, setWord] = useState(params.symboleAttente)

  const maxIndex = useMemo(
    () => params.wordsToDisplay.length - 1,
    [params.wordsToDisplay.length],
  )

  const revoir = () => {
    afficherMot(params.wordsToDisplay[params.index])
  }

  const suivant = () => {
    const currentIndex = params.index
    if (currentIndex < maxIndex) {
      setParams({ ...params, index: currentIndex + 1 })
      afficherMot(params.wordsToDisplay[currentIndex + 1])
    }
  }

  const afficherMot = word => {
    setWord(word)
    window.setTimeout(
      () => setWord(params.symboleAttente),
      getSliderValue(params.displayDuration),
    )
  }

  // TODO _ ajouter un listener sur la touche echap pour cacher le mot plus tôt

  // console.log('params', params);

  return (
    <>
      {params.step === STEPS.ATELIER && (
        <>
          <div className="lecture-flash-container">
            <p className="lecture-flash-current-word">{word}</p>
          </div>
          <div className="lecture-flash-buttons">
            <Box display="flex" gap={1}>
              {params.index > -1 && (
                <ButtonPrimary
                  disabled={word !== params.symboleAttente}
                  onClick={() => revoir()}
                >
                  Revoir
                </ButtonPrimary>
              )}
              {params.index < maxIndex && (
                <ButtonPrimary
                  disabled={word !== params.symboleAttente}
                  ml={2}
                  onClick={() => suivant()}
                >
                  Suivant
                </ButtonPrimary>
              )}
              {params.index === maxIndex && (
                <ButtonSecondary
                  disabled={word !== params.symboleAttente}
                  ml={2}
                  onClick={() =>
                    setParams({ ...params, step: STEPS.RESULTATS })
                  }
                >
                  Resultats
                </ButtonSecondary>
              )}
            </Box>
          </div>
        </>
      )}

      <Settings
        open={params.step !== STEPS.ATELIER}
        params={params}
        setParams={setParams}
      />
    </>
  )
}

export default LectureFlash
