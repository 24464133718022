import React from "react"

import { Box } from "@mui/material"

import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonTertiary,
} from "../../../../components"

const Boutons = () => {
  return (
    <>
      <Box display="flex" gap={2} mt={2} flexWrap={"wrap"}>
        <ButtonPrimary>Bouton primaire</ButtonPrimary>
        <ButtonSecondary>Bouton secondaire</ButtonSecondary>
        <ButtonTertiary>Bouton tertiaire</ButtonTertiary>
      </Box>
      <Box display="flex" gap={2} mt={2} flexWrap={"wrap"}>
        <ButtonPrimary disabled>Bouton primaire</ButtonPrimary>
        <ButtonSecondary disabled>Bouton secondaire</ButtonSecondary>
        <ButtonTertiary disabled>Bouton tertiaire</ButtonTertiary>
      </Box>
    </>
  )
}

export default Boutons
