import React from "react"

import { Button as MuiButton, styled } from "@mui/material"

const StyledButton = styled(MuiButton)(() => ({
  textWrap: "nowrap",
}))

// TODO _ typer + valeurs par defaut
const Button = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>
}

export default Button
