import React from "react"

import { Box, Paper, Typography } from "@mui/material"

const StyleGuideSection = ({ titre, children }) => (
  <Paper>
    <Box p={2}>
      <Typography variant="h5" gutterBottom>
        {titre}
      </Typography>
      {children}
    </Box>
  </Paper>
)

export default StyleGuideSection
