import React from "react"

import "./style.scss"

const Oups = () => {
  return (
    <div className="oups">
      <h3>Oups!</h3>
      <p>Cette page n'existe pas ...</p>
    </div>
  )
}

export default Oups
